<template>
     <div class="home">
       
          <div class="herocontent ">
               <div class="container">
                    <div>
                         <h1 data-aos="fade-in" data-aos-delay="100 " data-aos-duration="800">
                              Privacy Policy
                         </h1>

                         <div>
                              1 Dec 2022
                         </div>
                    </div>
               </div>
          </div>
          <div class="section2">
               <div class="container">
                    <ol>
                         <li class="level1">
                              <h1>INTRODUCTION</h1>

                              <ol>
                                   <li class="level2">
                                        <h2>Important information and who we are</h2>
                                        Welcome to SocialProof's Privacy and Data Protection Policy (“<b>Privacy Policy</b>”).<br /><br />
                                        At SocialProof (“<b>we</b>”, “<b>us</b>”, or “<b>our</b>”) we are committed to protecting and respecting your
                                        privacy and Personal Data in compliance with the United Kingdom General Data Protection Regulation (“<b>
                                             GDPR</b
                                        >”), the Data Protection Act 2018 and all other mandatory laws and regulations of the United Kingdom.<br /><br />
                                        This Privacy Policy explains how we collect, process and keep your data safe. The Privacy Policy will tell you
                                        about your privacy rights, how the law protects you, and inform our employees and staff members of all their
                                        obligations and protocols when processing data.<br /><br />
                                        The individuals from which we may gather and use data can include:
                                        <ul>
                                             <li class="level3">Customers</li>
                                        </ul>

                                        and any other people that the organisation has a relationship with or may need to contact.<br /><br />
                                        This Privacy Policy applies to all our employees and staff members and all Personal Data processed at any time
                                        by us.<br /><br />
                                   </li>

                                   <li class="level2">
                                        <h2>Who is Your Data Controller</h2>

                                        SocialProof is your Data Controller and responsible for your Personal Data. We are not obliged by the GDPR to
                                        appoint a data protection officer and have not voluntarily appointed one at this time. Therefore, any
                                        inquiries about your data should either be sent to us on email at contact@socialproof.works

                                        You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK
                                        supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to
                                        deal with your concerns before you approach the ICO so please contact us in the first instance.<br /><br />
                                   </li>

                                   <li class="level2">
                                        <h2>Processing Data on Behalf of a Controller and Processors’ responsibility to you</h2>

                                        In discharging our responsibilities as a Data Controller we have employees who will deal with your data on our
                                        behalf (known as “<b>Processors</b>”). Therefore, the responsibilities described below may be assigned to an
                                        individual, or may be taken to apply to the organisation as a whole. The Data Controller and our Processors
                                        have the following responsibilities:

                                        <ul>
                                             <li class="level3">
                                                  Ensure that all processing of Personal Data is governed by one of the legal bases laid out in the
                                                  GDPR (see 2.2 below for more information on those bases);
                                             </li>

                                             <li class="level3">
                                                  Ensure that Processors authorised to process Personal Data have committed themselves to
                                                  confidentiality or are under an appropriate statutory obligation of confidentiality;
                                             </li>

                                             <li class="level3">
                                                  Implement appropriate technical and organisational measures to ensure a level of security
                                                  appropriate to the risk associated with the processing of Personal Data;
                                             </li>

                                             <li class="level3">
                                                  Obtain the prior specific or general authorisation of the Controller before engaging another
                                                  Processor;
                                             </li>

                                             <li class="level3">
                                                  Assist the Controller in the fulfilment of the Controller's obligation to respond to requests for
                                                  exercising the data subject's rights;
                                             </li>

                                             <li class="level3">
                                                  Make available to the Controller all information necessary to demonstrate compliance with the
                                                  obligations laid down in the GDPR and allow for and contribute to audits, including inspections,
                                                  conducted by the Controller or another auditor mandated by the Controller;
                                             </li>

                                             <li class="level3">
                                                  Maintain a record of all categories of processing activities carried out on behalf of a Controller;
                                             </li>

                                             <li class="level3">
                                                  Cooperate, on request, with the supervisory authority in the performance of its tasks;
                                             </li>

                                             <li class="level3">
                                                  Ensure that any person acting under the authority of the Processor who has access to Personal Data
                                                  does not process Personal Data except on instructions from the Controller; and
                                             </li>

                                             <li class="level3">
                                                  Notify the Controller without undue delay after becoming aware of a Personal Data Breach;
                                             </li>
                                        </ul>
                                   </li>
                              </ol>
                         </li>

                         <li class="level1">
                              <h1>LEGAL BASIS FOR DATA COLLECTION</h1>

                              <ol>
                                   <li class="level2">
                                        <h2>Types of Data / Privacy Policy Scope</h2>

                                        “<b>Personal Data</b>” means any information about an individual from which that person can be identified. It
                                        does not include data where the identity has been removed (anonymous data). .<br /><br />

                                        We may collect, use, store and transfer different kinds of Personal Data about you which we have grouped
                                        together below. Not all of the following types of data will necessarily be collected from you but this is the
                                        full scope of data that we collect and when we collect it from you:

                                        <ul>
                                             <li class="level3">
                                                  Profile/Identity Data: This is data relating to your first name, last name, gender, date of birth.
                                             </li>
                                             <li>
                                                  Contact Data: This is data relating to your phone number, addresses, email addresses, phone numbers.
                                             </li>
                                        </ul>
                                        We do not collect any Special Categories of Personal Data about you (this includes details about your race or
                                        ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union
                                        membership, information about your health, and genetic and biometric data). Nor do we collect any information
                                        about criminal convictions and offences.
                                   </li>

                                   <li class="level2">
                                        <h2>The Legal Basis for Collecting That Data</h2>
                                        There are a number of justifiable reasons under the GDPR that allow collection and processing of Personal
                                        Data. The main avenues we rely on are:

                                        <ul>
                                             <li class="level3">
                                                  “<b>Consent</b>”: Certain situations allow us to collect your Personal Data, such as when you tick a
                                                  box that confirms you are happy to receive email newsletters from us, or ‘opt in’ to a service.
                                             </li>

                                             <li class="level3">
                                                  “<b>Contractual Obligations</b>”: We may require certain information from you in order to fulfil our
                                                  contractual obligations and provide you with the promised service.
                                             </li>

                                             <li class="level3">
                                                  “<b>Legal Compliance</b>”: We’re required by law to collect and process certain types of data, such
                                                  as fraudulent activity or other illegal actions.
                                             </li>

                                             <li class="level3">
                                                  “<b>Legitimate Interest</b>”: We might need to collect certain information from you to be able to
                                                  meet our legitimate interests - this covers aspects that can be reasonably expected as part of
                                                  running our business, that will not have a material impact on your rights, freedom or interests.
                                                  Examples could be your address, so that we know where to deliver something to, or your name, so that
                                                  we have a record of who to contact moving forwards.
                                             </li>
                                        </ul>
                                   </li>
                              </ol>
                         </li>

                         <li class="level1">
                              <h1>HOW WE USE YOUR PERSONAL DATA</h1>

                              <ol>
                                   <li class="level2">
                                        <h2>Our Uses</h2>

                                        We will only use your Personal Data when the law allows us to. Set out below is a table containing the
                                        different types of Personal Data we collect and the lawful basis for processing that data. Please refer to
                                        section 2.2 for more information on the lawful basis listed in the table below.<br /><br />

                                        Examples provided in the table below are indicative in nature and the purposes for which we use your data may
                                        be broader than described but we will never process your data without a legal basis for doing so and it is for
                                        a related purpose. For further inquiries please contact us.<br /><br />
                                   </li>

                                   <li class="level2">
                                        <h2>Change of Purpose</h2>
                                        We will only use your Personal Data for the purposes for which we collected it, unless we reasonably consider
                                        that we need to use it for another reason and that reason is compatible with the original purpose. If you wish
                                        to get an explanation as to how the processing for the new purpose is compatible with the original purpose,
                                        please contact us.<br /><br />

                                        If we need to use your Personal Data for an unrelated purpose, we will notify you and we will explain the
                                        legal basis which allows us to do so.<br /><br />
                                        Please note that we may process your Personal Data without your knowledge or consent, in compliance with the
                                        above rules, where this is required or permitted by law.
                                        <br /><br />
                                   </li>
                              </ol>
                         </li>

                         <li class="level1">
                              <h1>YOUR RIGHTS AND HOW YOU ARE PROTECTED BY US</h1>

                              <ol>
                                   <li class="level2">
                                        <h2>What Control Do I Have Over SocialProof’s Use Of My Personal Data?</h2>
                                        You may delete your account at any time – this will remove your account page from our systems and our related
                                        software.<br /><br />
                                        We guarantee this will delete all stored data.<br /><br />
                                        Your account information will be protected by a password for your privacy and security. You need to prevent
                                        unauthorized access to your account and personal information by selecting and protecting your password
                                        appropriately and limiting access to your computer or device and by signing off after you have finished
                                        accessing your account.<br /><br />
                                        You can access information associated with your account by logging into your account you created with us.<br /><br />
                                        Your account information will be protected by a password for your privacy and security. You need to prevent
                                        unauthorized access to your account and personal information by selecting and protecting your password
                                        appropriately and limiting access to your computer or device and by signing off after you have finished
                                        accessing your account.<br /><br />
                                        <I>
                                             California Privacy Rights: Under California Civil Code sections 1798.83-1798.84, California residents are
                                             entitled to ask us for a notice identifying the categories of personal customer information which we
                                             share with our affiliates and/or third parties for marketing purposes, and providing contact information
                                             for such affiliates and/or third parties. If you are a California resident and would like a copy of this
                                             notice, please submit a written request to contact@socialproof.works</I
                                        >
                                   </li>

                                   <li class="level2">
                                        <h2>How Does SocialProof Protect Customers' Personal Data?</h2>
                                        We are concerned with keeping your data secure and protecting it from inappropriate disclosure. Any Personal
                                        Data collected by us is only accessible by a limited number of employees who have special access rights to
                                        such systems and are bound by obligations of confidentiality. If and when we use subcontractors to store your
                                        data, we will not relinquish control of your Personal Data or expose it to security risks that would not have
                                        arisen had the data remained in our possession. However, unfortunately no transmission of data over the
                                        internet is guaranteed to be completely secure. It may be possible for third parties not under the control of
                                        SocialProof to intercept or access transmissions or private communications unlawfully. While we strive to protect
                                        your Personal Data, we cannot ensure or warrant the security of any Personal Data you transmit to us. Any such
                                        transmission is done at your own risk. If you believe that your interaction with us is no longer secure,
                                        please contact us.<br /><br />
                                   </li>

                                   <li class="level2">
                                        <h2>How to Request your Data and the Process for Obtaining it</h2>

                                        You will not have to pay a fee to access your Personal Data (or to exercise any of the other rights). However,
                                        if your request is clearly unfounded, we could refuse to comply with your request.<br /><br />

                                        We may need to request specific information from you to help us confirm your identity and ensure you have the
                                        right to access your Personal Data (or to exercise any of your other rights). This is a security measure to
                                        ensure that Personal Data is not disclosed to any person who has no right to receive it. We may also contact
                                        you to ask you for further information in relation to your request to speed up our response.
                                   </li>
                              </ol>
                         </li>

                         <li class="level1">
                              <h1>YOUR DATA AND THIRD PARTIES</h1>

                              <ol>
                                   <li class="level2">
                                        <h2>Will We Share Your Data With Third Parties?</h2>
                                        We may also share Personal Data with interested parties in the event that SocialProof anticipates a change in
                                        control or the acquisition of all or part of our business or assets or with interested parties in connection
                                        with the licensing of our technology.<br /><br />
                                        If SocialProof is sold or makes a sale or transfer, we may, in our sole discretion, transfer, sell or assign your
                                        Personal Data to a third party as part of or in connection with that transaction. Upon such transfer, the
                                        Privacy Policy of the acquiring entity may govern the further use of your Personal Data. In all other
                                        situations your data will still remain protected in accordance with this Privacy Policy (as amended from time
                                        to time).<br /><br />
                                        We may share your Personal Data at any time if required for legal reasons or in order to enforce our terms or
                                        this Privacy Policy.<br /><br />
                                   </li>

                                   <li class="level2">
                                        <h2>Third-Party Links</h2>

                                        This Site may include links to third-party websites, plug-ins and applications. Clicking on those links or
                                        enabling those connections may allow third parties to collect or share data about you. We do not control these
                                        third-party websites and are not responsible for their privacy statements. When you leave our Site, we
                                        encourage you to read the privacy policy of every website you visit.<br /><br />
                                   </li>
                              </ol>
                         </li>

                         <li class="level1">
                              <h1>HOW LONG WILL WE RETAIN YOUR DATA FOR?</h1>

                              We will only retain your Personal Data for as long as reasonably necessary to fulfil the purposes we collected it for.
                              We may retain your Personal Data for a longer period than usual in the event of a complaint or if we reasonably believe
                              there is a prospect of litigation in respect to our relationship with you.
                         </li>

                         <li class="level1">
                              <h1>INTERNATIONAL TRANSFER OF DATA</h1>

                              Your information may be stored and processed in the US or other countries or jurisdictions outside the US where SocialProof
                              has facilities. We are currently storing data in the EU and so, by using SocialProof, you are permitting and consenting to
                              the transfer of information, including Personal Data, outside of the US.
                         </li>

                         <li class="level1">
                              <h1>NOTIFICATION OF CHANGES AND ACCEPTANCE OF POLICY</h1>

                              We keep our Privacy Policy under review and will place any updates on this webpage. This version is dated 14 June 2021.
                              By using SocialProof, you consent to the collection and use of data by us as set out in this Privacy Policy. Continued access
                              or use of SocialProof will constitute your express acceptance of any modifications to this Privacy Policy.
                         </li>

                         <li class="level1">
                              <h1>INTERPRETATION</h1>
                              All uses of the word "including" mean "including but not limited to" and the enumerated examples are not intended to in
                              any way limit the term which they serve to illustrate. Any email addresses set out in this policy may be used solely for
                              the purpose for which they are stated to be provided, and any unrelated correspondence will be ignored. Unless otherwise
                              required by law, we reserve the right to not respond to emails, even if they relate to a legitimate subject matter for
                              which we have provided an email address. As a matter of common sense, you are more likely to get a reply if your request
                              or question is polite, reasonable and there is no relatively obvious other way to deal with or answer your concern or
                              question (e.g. FAQs, other areas of our website etc.). <br />
                              <br />
                              Our staff are not authorised to contract on behalf of SocialProof, waive rights or make representations (whether contractual
                              or otherwise). If anything contained in an email from a SocialProof address contradicts anything in this policy, our terms or
                              any official public announcement on our website, or is inconsistent with or amounts to a waiver of any SocialProof rights,
                              the email content will be read down to grant precedence to the latter. The only exception to this is genuine
                              correspondence expressed to be from the SocialProof legal department.
                         </li>
                    </ol>
               </div>
          </div>

        
     </div>
</template>

<script>
     // @ is an alias to /src
     import axios from "axios";

     export default {
          name: "Home",
          components: {},

          data() {
               return {
                    email: "",
                    isloading: false,
               };
          },

          methods: {},
     };
</script>

<style lang="scss" scoped>
     .home {
          max-width: 100vw;
          overflow-x: hidden;
     }
     .columns,
     .column {
          margin: 0 !important;
          padding: 0 !important;
     }
     nav {
          padding: 50px 0;
          .logo {
               height: 40px;
          }
          @media only screen and (max-width: 1200px) {
               padding: 30px 40px;
               margin: 0;
          }

          position: relative;
          min-height: 50px;
     }
     .herocontent {
          background: #fbfcff;
          text-align: left;
          position: relative;
          padding: 50px 0 50px 0px;

          @media only screen and (max-width: 800px) {
               padding-left: 30px;
               padding-right: 30px;
          }
          @media only screen and (max-width: 600px) {
               text-align: center;
          }

          .onlineshopping {
               max-width: 400px;
               display: block;
               margin: auto;

               @media only screen and (max-width: 600px) {
                    max-width: 300px;
                    margin-top: 40px;
               }
          }

          p {
               @media only screen and (max-width: 600px) {
                    margin: auto;
                    display: block;
               }
          }
     }

     .veralign {
          // position: absolute;
          // top: 44%;
          // transform: translate(0, -50%);
          // -webkit-transform: translate(0, -50%);
          // text-align: center;
          // width: 100%;
          // left: 0;
          // right: 0;
          // margin: 0 auto;
          // padding-left: 40px;
          // padding-right: 40px;
     }

     h1 {
          font-size: 50px;
          font-style: normal;
          font-weight: 500;

          color: #232d42;

          @media only screen and (max-width: 600px) {
               font-size: 37px;

               .break {
                    display: inline-block;
               }

               .bold {
                    // display: block;
               }
          }
     }

     h2 {
          font-size: 35px;
          font-style: normal;
          font-weight: 500;
          text-align: left;
          color: #232d42;
     }
     p {
          text-align: left;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: 33px;
          font-size: 19px;
          color: #647178;
          letter-spacing: 0.5px;
          @media only screen and (max-width: 600px) {
               text-align: center;
               max-width: 300px !important;

               font-size: 17px;
          }
     }

     ul {
          margin-top: 10px;
          li {
               list-style: disc;

               margin-left: 40px;
          }
     }

     ol {
          li.level1 {
               max-width: 900px;
               margin-top: 50px;
               h1 {
                    margin-bottom: 20px;
               }
          }

          li.level1::marker {
               font-size: 25px;
               font-weight: 500;
          }
          li.level2 {
               h1 {
                    margin-bottom: 20px;
               }
               margin-left: 40px;
               margin-top: 20px;
          }
          li.level2::marker {
               font-size: 23px;
               font-weight: 500;
          }
     }

     //  ol {
     //       counter-reset: foo;
     //  }
     //  .foo li {
     //       list-style-type: none;
     //  }
     //  .foo li::before {
     //       counter-increment: foo;
     //       content: "1." counter(foo) " ";
     //  }

     li::marker {
          unicode-bidi: isolate;
          font-variant-numeric: tabular-nums;
          text-transform: none;
          text-indent: 0px !important;
          text-align: start !important;
          text-align-last: start !important;

          color: black;
          font-size: 20px;
     }

     .section2 {
          text-align: left;
          font-size: 17px;
          font-style: normal;
          font-weight: 300;
          line-height: 33px;
          color: #647178;
          letter-spacing: 0.5px;

          padding: 20px 0 100px;
          h1 {
               font-size: 25px;
          }

          h2 {
               font-size: 23px;
          }

          .container {
               padding: 15px;
          }
     }
</style>
